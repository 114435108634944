export default [
  {
    path: '/car-search',
    name: 'CarSearch',
    component: () => import('@/views/Expertise/Search.vue'),
    meta: {
      pageTitle: 'Araç Sorgulama',
      breadcrumb: [
        {
          text: 'Sorgulama',
          active: true,
        },
      ],
      resource: 'I2_PORTAL',
      action: 'read',
    },
  },
  {
    path: '/car/:license_plate/:id',
    name: 'CarView',
    component: () => import('@/views/Expertise/View.vue'),
    meta: {
      pageTitle: 'Araç Sorgulama',
      breadcrumb: [
        {
          text: 'Sorgulama',
          to: '/car-search',
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'I2_PORTAL',
      action: 'read',
    },
  },
  {
    path: '/cars',
    name: 'CarList',
    component: () => import('@/views/Expertise/Index.vue'),
    meta: {
      pageTitle: 'Araç Listesi',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'I2_PORTAL',
      action: 'read',
    },
  },
]
