import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from '@/store/app'
import appConfig from '@/store/app-config'
import verticalMenu from '@/store/vertical-menu'
/* Dashboard */
// import dashboard from '@/store/Reports/dashboard'
/* Sıfır Satış */
// import saleOverviews from '@/store/Reports/sales/overviews'

/* Ekspertiz */
import swapcars from '@/store/expertise/swapcars'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    swapcars,
  },
  strict: process.env.DEV,
})
